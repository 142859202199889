Global Styles .App {
  text-align: center;
  padding-top: 2rem;
}
:root {
  --primary-color: #003558;
  --dark-color: #7ac142;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --font-color: #003558;
  --light-background: #ecedf0;
  --highlight-color: #7ac142;
  --background-color: white;

  margin-top: 88px;
}

td a {
  white-space: pre-wrap;
}

.padding-top-bottom {
  margin: 5px 0;
  border: 1px solid var(--light-background);
}
.display-block {
  display: block;
}

.background {
  background-color: var(--light-background);
}
.risk-table th,
.table th.th-background,
.table-row-even {
  background-color: var(--light-background);
  border: 3px solid #ffffff;
}
.risk-table td {
  line-height: 3rem;
  text-align: center !important;
}
.risk-table th {
  text-align: center !important;
}
.risk-table th,
.table-header {
  font-size: 1rem;
  font-weight: bold;
}
.risk-header-row {
  max-width: 750px;
  border-bottom: 1px solid var(--dark-color);
  margin: 0 15px;
}
.margin-auto {
  margin: auto;
  line-height: 2rem;
}
.icon-col {
  max-width: 30px;
  padding: 0;
}

.icon-col .fa-bars {
  color: var(--font-color) !important;
}
.margin-left {
  margin: 0 15px;
  line-height: 3rem;
}
.center .row img,
.center .row span,
.center .row .btn,
.center h3 {
  margin: 0 auto;
  text-align: center;
}

.card-header {
  background-color: var(--background-color);
  border: none;
  padding: 0.75rem 0;
}
.project_details_container .card {
  border: none;
}

.card {
  border: none;
}

.analysis-tree-container .card {
  border: 1px solid #000;
}
.card-body {
  padding: 0.75rem 0;
}
h3 {
  font-size: 1.2rem;
}
.center h3 {
  margin-bottom: 3rem;
}
.border-top-dark {
  border-top: 1px solid var(--dark-color);
  padding: 0.5rem 0;
}

.border-right-dark {
  border-right: 3px solid var(--dark-color);
}
.topbar img {
  position: fixed;
  top: 0px;
  left: 10px;
  z-index: 201;
}

/* Utilities */
.container,
.container-md {
  max-width: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: var(--background-color);
  color: var(--font-color);
}

.navbar .col a {
  float: left;
  font-size: 1rem;
  padding-left: 3rem;
}

.button-stack .row {
  padding-top: 0.5rem;
}

div {
  color: var(--font-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
}

/* conteiners */
/* project container */
.project-container {
  position: relative;
  width: 100%;
  height: 90vh;
}
/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  position: fixed;
  top: 25%;
  left: 50%;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: var(--font-color);
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-danger {
  display: inline-block;
  background: var(--danger-color);
  color: #fff;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-add {
  display: inline-block;
  background: var(--dark-color);
  color: #fff;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-edit-username {
  padding: 0px;
  height: 18px;
  background: none;
  color: #7ac142;
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--dark-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form-control,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 0.8rem;
  border: 1px solid var(--dark-color);
  margin: 0.2rem;
}

.form input[type='submit'],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.fa-filter {
  color: var(--font-color);
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
  border-top: 1px solid var(--light-background);
}

.table th {
  background: var(--light-background);
}

.table tbody {
  background: var(--light-color);
}

.tables {
  color: var(--font-color);
  height: 90vh;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: left;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  opacity: 0.9;
  padding: 0;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  margin: auto;
  font-size: 1rem;
  height: 100%;
}

.navbar a:hover {
  color: var(--dark-color);
  text-decoration: none;
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* Landing Page */
.landing {
  position: relative;
  background: url('./img/showcase.jpg') no-repeat bottom;
  overflow: hidden;
  height: 80vh;
  overflow-y: hidden;
}

.landing-inner {
  color: #fff;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80vh;
  overflow: hidden;
  overflow-y: hidden;
}
div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: transparent;
  padding: 50px;
  font-size: 20px;
}

/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp edu'
    'github github';
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top img {
  width: 200px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .skills {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
  margin-bottom: 1rem;
}

.profile-exp > div,
.profile-edu > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.profile-exp > div:last-child,
.profile-edu > div:last-child {
  border: 0;
}

.profile-exp p,
.profile-edu p {
  margin: 0.5rem 0;
}

.profile-github {
  grid-area: github;
}

.profile-github .repo {
  display: flex;
}

.profile-github .repo > div:first-child {
  flex: 7;
  flex-basis: 70%;
}

.profile-github > div:last-child {
  flex: 3;
  flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: center;
}

.post > div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

/* Mobile Styles */

.hide-sm {
  display: none;
}

/* Text Styles */
.x-large {
  font-size: 3rem;
}

.large {
  font-size: 2rem;
}

.lead {
  font-size: 1rem;
}

/* Navbar */
.navbar {
  display: block;
  text-align: center;
}

.navbar ul {
  text-align: center;
  justify-content: center;
}

.navbar h1 {
  margin-bottom: 0.5rem;
}

.navbar .welcome {
  display: none;
}
/* page-nav */
.page-nav {
  position: sticky;
  width: 100%;
  margin: 100;
  padding: 10;
  /* overflow: hidden; */
  float: top;
  align-self: right;
}
/* page-filter */
.page-filter {
  position: fixed;
  display: 'flow-root';
  width: fit-content;
  height: fit-content;
  /* z-index: 15; */
  top: 25%;
  left: 95%;
  margin: 100px 0 0 -200px;
  background: transparent;
}

.asset-filter {
  position: fixed;
  display: 'flow-root';
  width: fit-content;
  height: fit-content;
  /* z-index: 15; */
  top: 25%;
  left: 95%;
  margin: 100px 0 0 -145px;
  background: transparent;
}
/*  line */
.vl {
  position: fixed;
  border-right: 1.5px solid rgba(199, 214, 224, 0.267);
  left: 85%;
  margin-left: -3px;
  height: 100vh;
}
.hr {
  position: fixed;
  border-top: 110.5px solid rgba(134, 16, 75, 0.267);
}
/* Profiles Page */
.profile {
  grid-template-columns: 1fr;
  text-align: center;
}

.profile ul {
  display: none;
}

/* Profile Page */

.profile-top img,
.profile img {
  width: 200px;
  margin: auto;
}

.profile-grid {
  grid-template-areas:
    'top'
    'about'
    'exp'
    'edu'
    'github';
}

.profile-about .skills {
  flex-direction: column;
}

.dash-buttons a {
  display: block;
  width: 100%;
  margin-bottom: 0.2rem;
}

.post {
  grid-template-columns: 1fr;
}
.post a,
.post button {
  padding: 0.3rem 0.4rem;
}

/* dropdown */
/* Dropdown Button */
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
/* foreignObject */
.foreignObject {
  z-index: 0;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

/* MODAL */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-header {
  margin: auto;
  margin-top: -5px;
  font-weight: bold;
  font-size: 25px;
  border: none;
}

.modal-line {
  margin: auto;
  margin-top: -10px;
  width: 90%;
  border-top: 3px solid #7ac142;
}

.modal-body {
  margin: auto;
  width: 95%;
}

.confirm-modal-header {
  margin: auto;
  font-weight: bold;
  font-size: 20px;
  border: none;
  margin-top: 15px;
}

.confirm-modal-line {
  margin: auto;
  width: 80%;
  border-top: 2px solid #7ac142;
}

.confirm-modal-body {
  margin: auto;
  width: 95%;
  text-align: center;
}

.btn-cancel-delete {
  font-weight: bold;
  border: solid rgb(200, 200, 200) 1px;
  margin: auto;
}

.btn-confirm-delete {
  background: #ff5f4b;
  color: white;
  font-weight: bold;
  margin: auto;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.sidenav {
  height: 90vh;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: grey;
  color: white;
  text-align: center;
}

table,
th,
td {
  border: none;
  color: var(--font-color);
  font-size: inherit;
  margin-right: 2.1em;
  background-color: unset;
}

.hr-text {
  position: relative;
  outline: 0;
  border: 0;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  width: 40%;
  margin: auto;
}
.hr-text:before {
  content: '';
  background: grey;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  padding: 0 0.5em;
  line-height: 1.5em;
  background-color: #fcfcfa;
}

.credential-form {
  width: 150px;
  border: none;
  border-radius: 4px;
}
.plus-minus {
  width: 37px;
  height: 37px;
  color: white;
  background: #7ac142;
  font-size: 27px;
  padding-top: 0px;
}
.plus {
  padding-left: 11px;
}
.minus {
  padding-left: 14px;
}
.node-id {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  color: white;
  background: #7ac142;
  border-radius: 4px;
  font-size: 13px;
}
.node-name {
  padding-top: 5px;
  font-size: 13px;
  font-weight: bold;
  color: black;
}
.node-type {
  text-decoration: underline;
  font-size: 13px;
  color: black;
  margin-top: 5px;
}
.node-table-data {
  font-size: 11px;
  font-weight: bold;
  color: black;
}
.input-field {
  height: 37px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 2px solid #7ac142;
}
.btn-attack-tree {
  height: 37px;
  width: 150px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.exclamation {
  background: var(--danger-color);
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 4px;
}
.project-info-edit {
  width: 300px;
  height: 35px;
}
.no-border {
  border: none;
}
.description-edit {
  margin-top: 5px;
  width: 700px;
  height: 120px;
  border: 1px solid #7ac142;
  border-radius: 6px;
  padding: 5px;
}
.description-view {
  margin-top: 5px;
  width: 700px;
  white-space: pre-line;
}
.btn-icon {
  transform: scale(1.25);
}
.project-detail-td {
  border: none;
  min-width: 300px;
  font-weight: bold;
}
.project-details-label {
  width: 80px;
  font-weight: normal;
}
.bold {
  font-weight: bold;
}
.striped-comment:nth-child(odd) {
  background: #f2f2f2;
}
.striped-child-comment:nth-child(odd) {
  background: white;
}
.deleted-comment {
  color: rgb(100, 100, 100);
}
.icon {
  background: none;
  padding: 0;
}
.btn-small {
  padding: 1px 10px;
  margin-top: -10px;
}
.mr-15 {
  margin-right: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.col-project-filter {
  display: flex;
  flex-direction: row;
}
.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: var(--dark-color) !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: var(--dark-color) !important;
}
.expanded-table-td {
  padding-left: 5px !important;
  padding-right: 0px !important;
}
.btn-navbar {
  border: none;
  font-weight: bold;
  background: none;
  height: 60px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}
.btn-navbar:hover {
  color: var(--highlight-color);
}
.btn-navbar:focus {
  outline: none;
}

.display-linebreak {
  white-space: pre-line;
}

.risk-legend-divider {
  background: var(--primary-color) !important;
  height: 2px;
  width: 100%;
}

.placement {
  font-size: 13px;
  margin-left: 10px;
  color: black;
}

.margin-top-zero {
  margin-top: 0px;
}

.json-text {
  font: 16px Raleway, sans-serif;
}

input[type='number']::-webkit-inner-spin-button {
  transform: scale(2.1);
  margin-top: 1px;
}

.sidenav---sidenav-subnav---1EN61 {
  width: 200px;
  z-index: 2;
  position: relative;
  background: white;
}

.border-secondary.card {
  border: none;
}

/* node layout */
foreignObject .card-body {
  background: white;
  width: 400px;
  border: 1px solid;
  border-radius: 4px;
  min-height: 100px;
}

.sidebar-toggle {
  transform: scale(1.2);
  margin-top: 15px;
}

.toggler-div {
  margin-bottom: 15px;
}

.search-sub-option {
  width: 200px;
}

.logs-table-name {
  margin-top: -5px;
}
