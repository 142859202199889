table,
th,
td {
  font-size: inherit;
  margin-right: 2.1em;
  background-color: unset;
  color: var(--font-color);
}

.table td {
  background: white;
}
thead a {
  color: black;
}

th.descending {
  background: lightblue;
}
th.descending a::after {
  content: '↑';
  display: inline-block;
  margin-left: 1em;
}
th.ascending {
  background: lightblue;
}
th.ascending a::after {
  content: '↓';
  display: inline-block;
  margin-left: 1em;
}

thead td {
  position: sticky;
  top: 0;
}

th.emphasized {
  font-size: 1.2rem;
}

.risk-table th {
  border: none;
}

.risk-table td {
  padding: 2px;
  border: 3px solid var(--background-color);
}

table.risk-table {
  max-width: 700px;
}
